export const validNumber = (number) => number.replace(/[^0-9]/g, '').length === 11

export const checkMail = mail => (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(mail)

export const validateFile = ({ maxSize, typeList, sizeMessage }, file) => {
  if (file) {
    if (maxSize >= file.size || !maxSize) {
      if (typeList.some(item => file.type.replace('application/', '') === item) || !typeList) return ''
      else {
        return "Не корректный формат"
      }
    }
    else {
      return sizeMessage
    }
  }
  else return 'Выберите файл'
}
