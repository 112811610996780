import { useState, useEffect, memo, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { validNumber, checkMail } from "../../utils/inputField"
import dynamic from "next/dynamic"
import styles from './Input.module.scss'
import { CSSTransition } from "react-transition-group"

const InputMobile = dynamic(() => import('react-input-mask'), { ssr: false })

const initialPlaceholderByType = {
  tel: 'Телефон*',
  email: 'E-mail*',
}

const initialErrorMessageByType = {
  tel: 'Неверный телефон',
  email: 'Неверный E-mail',
}

const Input = memo(({
                      value = '',
                      onChange,
                      isDisabled,
                      mask,
                      name,
                      keyPress,
                      className,
                      isError,
                      type = 'text',
                      errorMessage = initialErrorMessageByType[ type ] || 'Заполните поле',
                      checkLocalMask,
                      placeholder = '',
                      disableClearAfterKeyPress,
                      targetError,
                      disableTelMask,
                      showErrorMessage
                    }) => {
  const [ _value, setValue ] = useState(value.slice())
  const [ componentError, setComponentError ] = useState(false)
  const ref = useRef()

  useEffect(() => {
    console.log(value, name)
    setValue(value)
    return () => {
      setValue('')
      setComponentError(false)
    }
  }, [ value ])

  const checkBlurError = () => {
    if (type === 'email') {
      setComponentError(value.length > 0 && !checkMail(value))
    }
  }

  useEffect(() => {
    checkLocalErrorHandler()
  }, [ checkLocalMask ])

  const checkLocalErrorHandler = async (isKeyPress) => {
    console.log(_value, name, 'checkLocalErrorHandler')
    if (checkLocalMask || isKeyPress) {
      let isValid
      if (type === 'tel') isValid = validNumber(_value)
      if (type === 'email') isValid = checkMail(_value)
      setComponentError(!isValid)
      console.log(validNumber(_value))
      if (isValid) {
        await keyPress(_value)
        if (!disableClearAfterKeyPress) clearField()
      }
    }
  }

  const changeHandler = ({ target }) => {
    let value
    if (isDisabled) return
    value = mask ? target.value.replace(mask, '') : target.value
    console.log(mask, value)
    setValue(value)
    if (onChange) onChange(value, name)
  }

  const clearField = () => {
    setValue('')
    if (onChange) onChange('', name)
  }

  const keyPressHandler = async target => {
    if (target.key === 'Enter') {
      if ([ 'tel', 'email' ].some(item => item === type) && !disableTelMask) {
        await checkLocalErrorHandler(true)
      }
      else {
        await keyPress(_value)
      }
    }
  }

  const keyPressAttr = keyPress ? { onKeyPress: keyPressHandler } : null

  const attr = {
    className: styles.root + (className ? ' ' + className : '') + (isError || componentError ? ' ' + styles.rootError : ''),
  }

  let allAttr = {
    type,
    className: 'body2' + (isDisabled ? ' ' + styles.disabled : ''),
    value: _value,
    ref: ref,
    disabled: isDisabled,
    onBlur: targetError ? checkBlurError : null,
    onChange: changeHandler,
    ...keyPressAttr
  }

  return (
    <>
      <div { ...attr }>
        { type === 'tel' && typeof window !== 'undefined' && !disableTelMask ?
          <Fragment>
            <InputMobile { ...allAttr } mask="+7 (999) 999-99-99"/>
          </Fragment> : <input { ...allAttr }/> }
        <span className={ styles.placeholder } style={ {
          top: !!_value.length ? '9px' : '50%',
        } }>{ placeholder || initialPlaceholderByType[ type ] }</span>
        { _value.length && !isDisabled ?
          <button onClick={ clearField } className={ styles.btn }>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="16" height="16" rx="8" fill="#333333" fillOpacity="0.1"/>
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M10.9116 5.08784C11.0288 5.20499 11.0288 5.39494 10.9116 5.5121L5.51164 10.9121C5.39449 11.0293 5.20454 11.0293 5.08738 10.9121C4.97022 10.7949 4.97022 10.605 5.08738 10.4878L10.4874 5.08784C10.6045 4.97068 10.7945 4.97068 10.9116 5.08784Z"
                    fill="#333333"/>
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M5.08738 5.08784C5.20454 4.97068 5.39449 4.97068 5.51164 5.08784L10.9116 10.4878C11.0288 10.605 11.0288 10.7949 10.9116 10.9121C10.7945 11.0293 10.6045 11.0293 10.4874 10.9121L5.08738 5.5121C4.97022 5.39494 4.97022 5.20499 5.08738 5.08784Z"
                    fill="#333333"/>
            </svg>
          </button>
          : null }
      </div>
      <CSSTransition unmountOnExit
                     appear={ true }
                     in={ errorMessage && (isError || componentError) && showErrorMessage } timeout={ 300 }
                     classNames={ 'inputErrorAnimation' }>
        <div className={ styles.error + ' body3 inputErrorAnimation' }>
          { errorMessage }
        </div>
      </CSSTransition>
    </>
  )
})

Input.propTypes = {
  type: PropTypes.oneOf([ 'text', 'tel', 'email' ]),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool
}

export default Input
