export default {
  leftSideBar: [
    {
      title: 'Клубы',
      href: '/clubs/',
      as: '/clubs/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Тренировки',
      href: '/profile/training/',
      as: '/profile/training/',
      isAuth: true,
      icon: 'Fitness'
    },
    {
      title: 'Блог о фитнесе',
      href: 'https://fitness.gde-luchshe.ru/blog/',
      as: 'https://fitness.gde-luchshe.ru/blog/',
      isNativeLink: true,
      target: '_blank',
      icon: 'Blog'
    },
    {
      title: 'Посоветовать клуб',
      icon: 'HousePlus',
      href: '/advise/',
      as: '/advise/'
    },
    {
      title: 'Контакты',
      href: '/contacts/',
      as: '/contacts/',
      icon: 'Phone'
    },
  ],
  mobileSideBar: [
    {
      title: 'Клубы',
      href: '/clubs/',
      as: '/clubs/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Профиль',
      href: '/profile/account/',
      as: '/profile/account/',
      isAuth: true,
      icon: 'Profile'
    },
    {
      title: 'Тренировки',
      href: '/profile/training/',
      as: '/profile/training/',
      isAuth: true,
      icon: 'Fitness'
    },
  ],
  otherOurService: [
    // {
    //   title: 'Интернет для бизнеса',
    //   href: 'https://telecom.gde-luchshe.ru/',
    //   isNativeLink: true,
    //   bg: 'linear-gradient(90.52deg, #1557FF 0%, #47A9E1 99.55%)'
    // },
    {
      title: 'Домашний интернет',
      href: 'https://internet.gde-luchshe.ru/',
      isNativeLink: true,
      bg: '#E94B0D'
    },
    {
      title: 'Интернет в офис',
      href: 'https://telecom.gde-luchshe.ru/',
      isNativeLink: true,
      bg: '#E94B0D'
    },
    // {
    //   title: 'Онлайн-курсы',
    //   href: 'https://telecom.gde-luchshe.ru',
    //   isNativeLink: true,
    //   bg: '#EB5757'
    // },
  ],
  additionalLinks: [
    {
      title: 'О проекте',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Партнерская программа',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Стать партнером',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Вакансии',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Контакты',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
  ],
  terms: {
    privacy: {
      href: '/privacy_policy/',
      as: '/privacy_policy/',
    },
    userAgreement: {
      href: '/user_agreement/',
      as: '/user_agreement/',
    }
  },
  profile: {
    account: {
      href: '/profile/account',
      as: '/profile/account',
      title: 'Личный кабинет'
    },
    training: {
      title: 'Тренировки',
      href: '/profile/training',
      as: '/profile/training',
    }
  },
  checkPrivateRoute(pathname) {
    let privateRoutes = [ '/profile/account', '/profile/training' ]
    return privateRoutes.indexOf(pathname) >= 0
  }
}
