import { checkMail } from "@/utils/inputField"
import { openModal } from "@/store/modal/actions"

export const SET_ADVICE_CLUB_FETCH = 'SET_ADVICE_CLUB_FETCH'
import { createParamsApi } from "../../../api"
import { fetcher } from "@/utils/fetcher"

export const adviceClub = ({ email, clubName }) => async (dispatch, getState) => {
  dispatch({ type: SET_ADVICE_CLUB_FETCH, status: true })
  const isValid = validateAdvice({ email, clubName })
  if (isValid) {
    const api = createApi({ email, clubName })
    const data = await fetcher(api)
    await setDataRes({ data })(dispatch, getState)
    dispatch({ type: SET_ADVICE_CLUB_FETCH, status: false })
  }
}

const setDataRes = ({ data }) => (dispatch, getState) => {
  openModal(getState().modal.modalNames.adviseSuccess)(dispatch, getState)
}

export const createApi = ({ email, clubName }) => {
  const api = createParamsApi({ method: 'adviseClub', section: 'club', errorMessage: 'adviceClub' })
  api.params = {
    email,
    name: clubName
  }
  return api
}

export const validateAdvice = ({ email, clubName = '' }) => {
  return checkMail(email) && clubName.length > 0
}
